import React from "react"
import RacepageFS from "../components/racepageFS"

const Race2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Giro d'Italia",
      edition: 2021,
      what: "Startlist",
      updated: "2021-05-24 20:36:04",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21798: {
        teamId: 21798,
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00ebn Team",
        teamNationCode: "FRA",
      },
      21799: {
        teamId: 21799,
        teamUciCode: "APT",
        teamName: "Astana-Premier Tech",
        teamNationCode: "KAZ",
      },
      21800: {
        teamId: 21800,
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21805: {
        teamId: 21805,
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      21807: {
        teamId: 21807,
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      21808: {
        teamId: 21808,
        teamUciCode: "DQT",
        teamName: "Deceuninck-Quick Step",
        teamNationCode: "BEL",
      },
      21811: {
        teamId: 21811,
        teamUciCode: "EFN",
        teamName: "EF Education-NIPPO",
        teamNationCode: "USA",
      },
      21813: {
        teamId: 21813,
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21817: {
        teamId: 21817,
        teamUciCode: "ISN",
        teamName: "Israel Start-Up Nation",
        teamNationCode: "ISR",
      },
      21819: {
        teamId: 21819,
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21821: {
        teamId: 21821,
        teamUciCode: "BEX",
        teamName: "Team BikeExchange",
        teamNationCode: "AUS",
      },
      21822: {
        teamId: 21822,
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21824: {
        teamId: 21824,
        teamUciCode: "TQA",
        teamName: "Team Qhubeka ASSOS",
        teamNationCode: "RSA",
      },
      21826: {
        teamId: 21826,
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21829: {
        teamId: 21829,
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21832: {
        teamId: 21832,
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "GER",
      },
      21836: {
        teamId: 21836,
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21838: {
        teamId: 21838,
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21844: {
        teamId: 21844,
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
      },
      21846: {
        teamId: 21846,
        teamUciCode: "ANS",
        teamName: "Androni Giocattoli-Sidermec",
        teamNationCode: "ITA",
      },
      21851: {
        teamId: 21851,
        teamUciCode: "BCF",
        teamName: "Bardiani CSF Faizan\u00e8",
        teamNationCode: "ITA",
      },
      21855: {
        teamId: 21855,
        teamUciCode: "IWG",
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        teamNationCode: "BEL",
      },
      22761: {
        teamId: 22761,
        teamUciCode: "EOK",
        teamName: "EOLO-KOMETA Cycling Team",
        teamNationCode: "ITA",
      },
    },
    riders: {
      1: {
        id: 58275,
        startno: 1,
        firstName: "Egan",
        lastName: "Bernal",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-01-13",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      2: {
        id: 871,
        startno: 2,
        firstName: "Jonathan",
        lastName: "Castroviejo",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1987-04-27",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      3: {
        id: 27099,
        startno: 3,
        firstName: "Filippo",
        lastName: "Ganna",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-07-25",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      4: {
        id: 27124,
        startno: 4,
        firstName: "Daniel",
        lastName: "Martinez",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1996-04-25",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      5: {
        id: 28781,
        startno: 5,
        firstName: "Gianni",
        lastName: "Moscon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-20",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      6: {
        id: 41249,
        startno: 6,
        firstName: "Jhonnatan",
        lastName: "Narvaez",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1997-03-04",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      7: {
        id: 3929,
        startno: 7,
        firstName: "Salvatore",
        lastName: "Puccio",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-31",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      8: {
        id: 37280,
        startno: 8,
        firstName: "Pavel",
        lastName: "Sivakov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1997-07-11",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "N",
      },
      11: {
        id: 918,
        startno: 11,
        firstName: "Tony",
        lastName: "Gallopin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-05-24",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      12: {
        id: 22267,
        startno: 12,
        firstName: "Fran\u00e7ois",
        lastName: "Bidard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-03-19",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "N",
      },
      13: {
        id: 17215,
        startno: 13,
        firstName: "Geoffrey",
        lastName: "Bouchard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-04-01",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      14: {
        id: 51244,
        startno: 14,
        firstName: "Cl\u00e9ment",
        lastName: "Champoussin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-05-29",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "N",
      },
      15: {
        id: 12800,
        startno: 15,
        firstName: "Alexis",
        lastName: "Gougeard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-03-05",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      16: {
        id: 31612,
        startno: 16,
        firstName: "Lawrence",
        lastName: "Naesen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-08-28",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      17: {
        id: 22389,
        startno: 17,
        firstName: "Andrea",
        lastName: "Vendrame",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-07-20",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      18: {
        id: 14123,
        startno: 18,
        firstName: "Lawrence",
        lastName: "Warbasse",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1990-06-28",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      21: {
        id: 8377,
        startno: 21,
        firstName: "Tim",
        lastName: "Merlier",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-10-30",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "N",
      },
      22: {
        id: 28169,
        startno: 22,
        firstName: "Dries",
        lastName: "De Bondt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-07-04",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      23: {
        id: 6229,
        startno: 23,
        firstName: "Jimmy",
        lastName: "Janssens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-05-30",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      24: {
        id: 8207,
        startno: 24,
        firstName: "Alexander",
        lastName: "Krieger",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1991-11-28",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      25: {
        id: 37430,
        startno: 25,
        firstName: "Senne",
        lastName: "Leysen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-03-18",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      26: {
        id: 4779,
        startno: 26,
        firstName: "Oscar",
        lastName: "Riesebeek",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-12-23",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      27: {
        id: 7964,
        startno: 27,
        firstName: "Gianni",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-11-19",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      28: {
        id: 8078,
        startno: 28,
        firstName: "Louis",
        lastName: "Vervaeke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-10-06",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      31: {
        id: 68868,
        startno: 31,
        firstName: "Alexander",
        lastName: "Cepeda",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1998-06-16",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      32: {
        id: 9008,
        startno: 32,
        firstName: "Simon",
        lastName: "Pellaud",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1992-11-06",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      33: {
        id: 102083,
        startno: 33,
        firstName: "Andrii",
        lastName: "Ponomar",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "2002-09-05",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      34: {
        id: 33285,
        startno: 34,
        firstName: "Simone",
        lastName: "Ravanelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-07-04",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      35: {
        id: 9451,
        startno: 35,
        firstName: "Eduardo",
        lastName: "Sepulveda",
        nationCode: "ARG",
        nationName: "Argentina",
        birthDate: "1991-06-13",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      36: {
        id: 33341,
        startno: 36,
        firstName: "Filippo",
        lastName: "Tagliani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-08-14",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      37: {
        id: 91583,
        startno: 37,
        firstName: "Natnael",
        lastName: "Tesfazion",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1999-05-23",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      38: {
        id: 48505,
        startno: 38,
        firstName: "Nicola",
        lastName: "Venchiarutti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-10-07",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      41: {
        id: 37281,
        startno: 41,
        firstName: "Aleksandr",
        lastName: "Vlasov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1996-04-23",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      42: {
        id: 48461,
        startno: 42,
        firstName: "Samuele",
        lastName: "Battistella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-11-14",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      43: {
        id: 1580,
        startno: 43,
        firstName: "Fabio",
        lastName: "Felline",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-03-29",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      44: {
        id: 872,
        startno: 44,
        firstName: "Gorka",
        lastName: "Izagirre",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1987-10-07",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      45: {
        id: 45445,
        startno: 45,
        firstName: "Vadim",
        lastName: "Pronskiy",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1998-06-04",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      46: {
        id: 26,
        startno: 46,
        firstName: "Luis Le\u00f3n",
        lastName: "S\u00e1nchez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-12-24",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      47: {
        id: 40783,
        startno: 47,
        firstName: "Matteo",
        lastName: "Sobrero",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-05-14",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      48: {
        id: 64106,
        startno: 48,
        firstName: "Harold",
        lastName: "Tejada",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-04-27",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      51: {
        id: 2278,
        startno: 51,
        firstName: "Mikel",
        lastName: "Landa",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-12-13",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "N",
      },
      52: {
        id: 163,
        startno: 52,
        firstName: "Yukiya",
        lastName: "Arashiro",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1984-09-22",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      53: {
        id: 4863,
        startno: 53,
        firstName: "Pello",
        lastName: "Bilbao",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-02-25",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      54: {
        id: 1006,
        startno: 54,
        firstName: "Damiano",
        lastName: "Caruso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-10-12",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      55: {
        id: 37259,
        startno: 55,
        firstName: "Gino",
        lastName: "M\u00e4der",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-01-04",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "N",
      },
      56: {
        id: 16687,
        startno: 56,
        firstName: "Matej",
        lastName: "Mohoric",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1994-10-19",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "N",
      },
      57: {
        id: 3050,
        startno: 57,
        firstName: "Jan",
        lastName: "Tratnik",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1990-02-23",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      58: {
        id: 886,
        startno: 58,
        firstName: "Rafael",
        lastName: "Valls",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1987-06-25",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      61: {
        id: 486,
        startno: 61,
        firstName: "Giovanni",
        lastName: "Visconti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1983-01-13",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      62: {
        id: 2312,
        startno: 62,
        firstName: "Enrico",
        lastName: "Battaglin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-11-17",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      63: {
        id: 27265,
        startno: 63,
        firstName: "Giovanni",
        lastName: "Carboni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-08-31",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      64: {
        id: 33402,
        startno: 64,
        firstName: "Filippo",
        lastName: "Fiorelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-11-19",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      65: {
        id: 20466,
        startno: 65,
        firstName: "Davide",
        lastName: "Gabburo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-04-01",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      66: {
        id: 20386,
        startno: 66,
        firstName: "Umberto",
        lastName: "Marengo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-07-21",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      67: {
        id: 66439,
        startno: 67,
        firstName: "Filippo",
        lastName: "Zana",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-03-18",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      68: {
        id: 50033,
        startno: 68,
        firstName: "Samuele",
        lastName: "Zoccarato",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-01-09",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      71: {
        id: 784,
        startno: 71,
        firstName: "Peter",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1990-01-26",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      72: {
        id: 66495,
        startno: 72,
        firstName: "Giovanni",
        lastName: "Aleotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-05-25",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      73: {
        id: 3224,
        startno: 73,
        firstName: "Cesare",
        lastName: "Benedetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-03",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      74: {
        id: 361,
        startno: 74,
        firstName: "Maciej",
        lastName: "Bodnar",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1985-03-07",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      75: {
        id: 9344,
        startno: 75,
        firstName: "Emanuel",
        lastName: "Buchmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-18",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "N",
      },
      76: {
        id: 27268,
        startno: 76,
        firstName: "Matteo",
        lastName: "Fabbro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-04-10",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      77: {
        id: 9302,
        startno: 77,
        firstName: "Felix",
        lastName: "Gro\u00dfschartner",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-12-23",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      78: {
        id: 777,
        startno: 78,
        firstName: "Daniel",
        lastName: "Oss",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-01-13",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      81: {
        id: 1310,
        startno: 81,
        firstName: "Elia",
        lastName: "Viviani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-02-07",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      82: {
        id: 6257,
        startno: 82,
        firstName: "Natnael",
        lastName: "Berhane",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1991-01-05",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "N",
      },
      83: {
        id: 20454,
        startno: 83,
        firstName: "Simone",
        lastName: "Consonni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-12",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      84: {
        id: 3044,
        startno: 84,
        firstName: "Nicolas",
        lastName: "Edet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-12-03",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "N",
      },
      85: {
        id: 38937,
        startno: 85,
        firstName: "Victor",
        lastName: "Lafay",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-01-17",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      86: {
        id: 27303,
        startno: 86,
        firstName: "R\u00e9my",
        lastName: "Rochas",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-05-18",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      87: {
        id: 122,
        startno: 87,
        firstName: "Fabio",
        lastName: "Sabatini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1985-02-18",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      88: {
        id: 40385,
        startno: 88,
        firstName: "Attilio",
        lastName: "Viviani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-10-18",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      91: {
        id: 84019,
        startno: 91,
        firstName: "Remco",
        lastName: "Evenepoel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-01-25",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      92: {
        id: 50303,
        startno: 92,
        firstName: "Joao",
        lastName: "Almeida",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1998-08-05",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      93: {
        id: 27111,
        startno: 93,
        firstName: "Remi",
        lastName: "Cavagna",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-08-10",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      94: {
        id: 37401,
        startno: 94,
        firstName: "Mikkel",
        lastName: "Honore",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-01-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      95: {
        id: 1298,
        startno: 95,
        firstName: "Iljo",
        lastName: "Keisse",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1982-12-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      96: {
        id: 27295,
        startno: 96,
        firstName: "James",
        lastName: "Knox",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-11-04",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      97: {
        id: 14791,
        startno: 97,
        firstName: "Fausto",
        lastName: "Masnada",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-11-06",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "N",
      },
      98: {
        id: 3165,
        startno: 98,
        firstName: "Pieter",
        lastName: "Serry",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      101: {
        id: 18059,
        startno: 101,
        firstName: "Hugh",
        lastName: "Carthy",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1994-07-09",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      102: {
        id: 33263,
        startno: 102,
        firstName: "Jonathan",
        lastName: "Caicedo",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1993-03-28",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "N",
      },
      103: {
        id: 51252,
        startno: 103,
        firstName: "Simon",
        lastName: "Carr",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-08-29",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      104: {
        id: 16992,
        startno: 104,
        firstName: "Ruben",
        lastName: "Guerreiro",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1994-07-06",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "N",
      },
      105: {
        id: 920,
        startno: 105,
        firstName: "Jens",
        lastName: "Keukeleire",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-23",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      106: {
        id: 38164,
        startno: 106,
        firstName: "Julius",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-10-23",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      107: {
        id: 20584,
        startno: 107,
        firstName: "Alberto",
        lastName: "Bettiol",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-10-29",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      109: {
        id: 799,
        startno: 109,
        firstName: "Tejay",
        lastName: "Van Garderen",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1988-08-12",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      111: {
        id: 1864,
        startno: 111,
        firstName: "Manuel",
        lastName: "Belletti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1985-10-14",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "N",
      },
      112: {
        id: 37351,
        startno: 112,
        firstName: "Vincenzo",
        lastName: "Albanese",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-11-12",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      113: {
        id: 6241,
        startno: 113,
        firstName: "Mark",
        lastName: "Christian",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-11-20",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      114: {
        id: 54530,
        startno: 114,
        firstName: "Marton",
        lastName: "Dina",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1996-04-11",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      115: {
        id: 27271,
        startno: 115,
        firstName: "Lorenzo",
        lastName: "Fortunato",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-05-09",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      116: {
        id: 580,
        startno: 116,
        firstName: "Francesco",
        lastName: "Gavazzi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1984-08-01",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      117: {
        id: 20579,
        startno: 117,
        firstName: "Edward",
        lastName: "Ravasi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-06-05",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      118: {
        id: 34369,
        startno: 118,
        firstName: "Samuele",
        lastName: "Rivi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-05-11",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      121: {
        id: 6222,
        startno: 121,
        firstName: "Rudy",
        lastName: "Molard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-17",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      122: {
        id: 26520,
        startno: 122,
        firstName: "Matteo",
        lastName: "Badilatti",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1992-07-30",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      123: {
        id: 6260,
        startno: 123,
        firstName: "Antoine",
        lastName: "Duchesne",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1991-09-12",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      124: {
        id: 38923,
        startno: 124,
        firstName: "Simon",
        lastName: "Guglielmi",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-07-01",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      125: {
        id: 6184,
        startno: 125,
        firstName: "Sebastien",
        lastName: "Reichenbach",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1989-05-28",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "N",
      },
      126: {
        id: 46941,
        startno: 126,
        firstName: "Romain",
        lastName: "Seigle",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-10-11",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      127: {
        id: 69890,
        startno: 127,
        firstName: "Attila",
        lastName: "Valter",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1998-06-12",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      128: {
        id: 47122,
        startno: 128,
        firstName: "Lars",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-07-07",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      131: {
        id: 6269,
        startno: 131,
        firstName: "Jan",
        lastName: "Hirt",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1991-01-22",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      132: {
        id: 31066,
        startno: 132,
        firstName: "Quinten",
        lastName: "Hermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-07-29",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      133: {
        id: 7723,
        startno: 133,
        firstName: "Wesley",
        lastName: "Kreder",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1990-11-04",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      134: {
        id: 33307,
        startno: 134,
        firstName: "Riccardo",
        lastName: "Minali",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-04-19",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      135: {
        id: 3147,
        startno: 135,
        firstName: "Andrea",
        lastName: "Pasqualon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1988-01-02",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      136: {
        id: 20378,
        startno: 136,
        firstName: "Simone",
        lastName: "Petilli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      137: {
        id: 346,
        startno: 137,
        firstName: "Rein",
        lastName: "Taaram\u00e4e",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1987-04-24",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      138: {
        id: 10802,
        startno: 138,
        firstName: "Taco",
        lastName: "van der Hoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-04",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      141: {
        id: 51,
        startno: 141,
        firstName: "Daniel",
        lastName: "Martin",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1986-08-20",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      142: {
        id: 3287,
        startno: 142,
        firstName: "Patrick",
        lastName: "Bevin",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1991-05-02",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      143: {
        id: 875,
        startno: 143,
        firstName: "Matthias",
        lastName: "Br\u00e4ndle",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1989-12-07",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      144: {
        id: 780,
        startno: 144,
        firstName: "Davide",
        lastName: "Cimolai",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-13",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      145: {
        id: 3103,
        startno: 145,
        firstName: "Alessandro",
        lastName: "De Marchi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1986-05-19",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "N",
      },
      146: {
        id: 1009,
        startno: 146,
        firstName: "Alex",
        lastName: "Dowsett",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1988-10-03",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "N",
      },
      147: {
        id: 16660,
        startno: 147,
        firstName: "Krists",
        lastName: "Neilands",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1994-08-18",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "N",
      },
      148: {
        id: 68377,
        startno: 148,
        firstName: "Guy",
        lastName: "Niv",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1994-03-08",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      151: {
        id: 3352,
        startno: 151,
        firstName: "George",
        lastName: "Bennett",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-04-07",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      152: {
        id: 27097,
        startno: 152,
        firstName: "Edoardo",
        lastName: "Affini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-06-24",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      153: {
        id: 18564,
        startno: 153,
        firstName: "Koen",
        lastName: "Bouwman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-02",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      154: {
        id: 45433,
        startno: 154,
        firstName: "David",
        lastName: "Dekker",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-02-02",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "N",
      },
      155: {
        id: 37297,
        startno: 155,
        firstName: "Tobias",
        lastName: "Foss",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1997-05-25",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      156: {
        id: 7720,
        startno: 156,
        firstName: "Dylan",
        lastName: "Groenewegen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-06-21",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "N",
      },
      157: {
        id: 369,
        startno: 157,
        firstName: "Paul",
        lastName: "Martens",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1983-10-26",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      158: {
        id: 729,
        startno: 158,
        firstName: "Jos",
        lastName: "Van Emden",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-02-18",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "N",
      },
      161: {
        id: 16573,
        startno: 161,
        firstName: "Caleb",
        lastName: "Ewan",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-07-11",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "N",
      },
      162: {
        id: 9042,
        startno: 162,
        firstName: "Jasper",
        lastName: "De Buyst",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-11-24",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "N",
      },
      163: {
        id: 1783,
        startno: 163,
        firstName: "Thomas",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-11-06",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "N",
      },
      164: {
        id: 38818,
        startno: 164,
        firstName: "Kobe",
        lastName: "Goossens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-04-29",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "N",
      },
      165: {
        id: 840,
        startno: 165,
        firstName: "Roger",
        lastName: "Kluge",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-02-05",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "N",
      },
      166: {
        id: 2281,
        startno: 166,
        firstName: "Tomasz",
        lastName: "Marczynski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1984-03-06",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "N",
      },
      167: {
        id: 46003,
        startno: 167,
        firstName: "Stefano",
        lastName: "Oldani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-01-10",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      168: {
        id: 39387,
        startno: 168,
        firstName: "Harm",
        lastName: "Vanhoucke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-06-17",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      171: {
        id: 25726,
        startno: 171,
        firstName: "Marc",
        lastName: "Soler",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1993-11-22",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "N",
      },
      172: {
        id: 532,
        startno: 172,
        firstName: "Dario",
        lastName: "Cataldo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1985-03-17",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      173: {
        id: 68206,
        startno: 173,
        firstName: "Matteo",
        lastName: "Jorgenson",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1999-07-01",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      174: {
        id: 1008,
        startno: 174,
        firstName: "Nelson",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1989-03-06",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      175: {
        id: 10996,
        startno: 175,
        firstName: "Antonio",
        lastName: "Pedrero",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1991-10-23",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      176: {
        id: 77970,
        startno: 176,
        firstName: "Einer",
        lastName: "Rubio",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1998-02-22",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      177: {
        id: 7011,
        startno: 177,
        firstName: "Albert",
        lastName: "Torres",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-04-26",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      178: {
        id: 14126,
        startno: 178,
        firstName: "Davide",
        lastName: "Villella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-06-27",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      181: {
        id: 6242,
        startno: 181,
        firstName: "Simon",
        lastName: "Yates",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-08-07",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      182: {
        id: 2978,
        startno: 182,
        firstName: "Michael",
        lastName: "Hepburn",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-08-17",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      183: {
        id: 1715,
        startno: 183,
        firstName: "Christopher",
        lastName: "Juul-Jensen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1989-07-06",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      184: {
        id: 1018,
        startno: 184,
        firstName: "Tanel",
        lastName: "Kangert",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1987-03-11",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      185: {
        id: 767,
        startno: 185,
        firstName: "Cameron",
        lastName: "Meyer",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1988-01-11",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      186: {
        id: 868,
        startno: 186,
        firstName: "Mikel",
        lastName: "Nieve",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1984-05-26",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      187: {
        id: 16576,
        startno: 187,
        firstName: "Nick",
        lastName: "Schultz",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-09-13",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      188: {
        id: 42175,
        startno: 188,
        firstName: "Callum",
        lastName: "Scotson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-08-10",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      191: {
        id: 37446,
        startno: 191,
        firstName: "Jai",
        lastName: "Hindley",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-05-05",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "N",
      },
      192: {
        id: 5306,
        startno: 192,
        firstName: "Nikias",
        lastName: "Arndt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1991-11-18",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      193: {
        id: 2275,
        startno: 193,
        firstName: "Romain",
        lastName: "Bardet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-11-09",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      194: {
        id: 16817,
        startno: 194,
        firstName: "Nico",
        lastName: "Denz",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-02-15",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      195: {
        id: 42185,
        startno: 195,
        firstName: "Chris",
        lastName: "Hamilton",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1995-05-18",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      196: {
        id: 37756,
        startno: 196,
        firstName: "Max",
        lastName: "Kanter",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-10-22",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      197: {
        id: 189,
        startno: 197,
        firstName: "Nicolas",
        lastName: "Roche",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1984-07-03",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      198: {
        id: 37447,
        startno: 198,
        firstName: "Michael",
        lastName: "Storer",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1997-02-28",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      201: {
        id: 2982,
        startno: 201,
        firstName: "Giacomo",
        lastName: "Nizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-01-30",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "N",
      },
      202: {
        id: 19274,
        startno: 202,
        firstName: "Victor",
        lastName: "Campenaerts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-28",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      203: {
        id: 16777,
        startno: 203,
        firstName: "Kilian",
        lastName: "Frankiny",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1994-01-26",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      204: {
        id: 4001,
        startno: 204,
        firstName: "Bert-Jan",
        lastName: "Lindeman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-06-16",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      205: {
        id: 452,
        startno: 205,
        firstName: "Domenico",
        lastName: "Pozzovivo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1982-11-30",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "N",
      },
      206: {
        id: 69502,
        startno: 206,
        firstName: "Mauro",
        lastName: "Schmid",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1999-12-04",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      207: {
        id: 9210,
        startno: 207,
        firstName: "Max",
        lastName: "Walscheid",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-06-13",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      208: {
        id: 6557,
        startno: 208,
        firstName: "Lukasz",
        lastName: "Wisniowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1991-12-07",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      211: {
        id: 133,
        startno: 211,
        firstName: "Vincenzo",
        lastName: "Nibali",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1984-11-14",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      212: {
        id: 2741,
        startno: 212,
        firstName: "Gianluca",
        lastName: "Brambilla",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-22",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      213: {
        id: 20384,
        startno: 213,
        firstName: "Giulio",
        lastName: "Ciccone",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-12-20",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      214: {
        id: 191,
        startno: 214,
        firstName: "Koen",
        lastName: "De Kort",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1982-09-08",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      215: {
        id: 32493,
        startno: 215,
        firstName: "Amanuel",
        lastName: "Gebreigzabhier",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1994-08-17",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      216: {
        id: 581,
        startno: 216,
        firstName: "Bauke",
        lastName: "Mollema",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-11-26",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      217: {
        id: 20397,
        startno: 217,
        firstName: "Jacopo",
        lastName: "Mosca",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-08-29",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      218: {
        id: 37719,
        startno: 218,
        firstName: "Matteo",
        lastName: "Moschetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-08-14",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      221: {
        id: 11011,
        startno: 221,
        firstName: "Davide",
        lastName: "Formolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-10-25",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      222: {
        id: 20511,
        startno: 222,
        firstName: "Valerio",
        lastName: "Conti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-03-30",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      223: {
        id: 45987,
        startno: 223,
        firstName: "Alessandro",
        lastName: "Covi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-09-28",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      224: {
        id: 3356,
        startno: 224,
        firstName: "Joe",
        lastName: "Dombrowski",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1991-05-12",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "N",
      },
      225: {
        id: 18575,
        startno: 225,
        firstName: "Fernando",
        lastName: "Gaviria",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-08-19",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      226: {
        id: 34624,
        startno: 226,
        firstName: "Sebastian",
        lastName: "Molano",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-04-11",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      227: {
        id: 624,
        startno: 227,
        firstName: "Maximiliano",
        lastName: "Richeze",
        nationCode: "ARG",
        nationName: "Argentina",
        birthDate: "1983-03-07",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      228: {
        id: 794,
        startno: 228,
        firstName: "Diego",
        lastName: "Ulissi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-07-15",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Giro d'Italia"
  const raceID = 13

  return (
    <RacepageFS
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2021
